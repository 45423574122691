<template>
  <div align="center" id="weather">
    <div v-if="weather">
      <div
        class="w-weather-heading-img"
        :style="{
          'background-image': `url('https://www.mapquestapi.com/staticmap/v5/map?key=frWnGmgOv5ZT70Hzhf6VQRrbDvdYm2sc&center=${coords}&type=sat&size=1100,500%402x')`,
        }"
      />
      <div class="w-triangle flipped" />
      <div class="w-content" align="left">
        <h1 class="w-floating-title">
          {{ location.city || "Unincorporated Area" }}
        </h1>
        <div class="grid grid-cols-3 gap-4">
          <div class="w-header-card col-span-3 sm:col-span-1">
            <img class="icon" :src="forecast.properties.periods[0].icon" />
            <span class="big">
              {{ $f(weather.properties.temperature.values[0].value) }} ℉
            </span>
            <small>right now</small>
            <span class="desc">
              {{ forecast.properties.periods[0].shortForecast }}
            </span>
          </div>
          <div align="right" class="col-span-3 sm:col-span-2 icon-in-text">
            <span
              ><MjIcon name="map-pin" />
              {{ location.city || "Unincorporated Area" }},
              {{ location.state }} –
              <a
                target="_blank"
                :href="`https://mapquest.com/latlng/${coords}`"
                >{{ coords }}</a
              ></span
            ><br />
            <span
              ><MjIcon name="clock" />
              {{
                $d(
                  forecast.properties.generatedAt,
                  forecast.properties.periods[0].startTime
                )
              }}</span
            >
          </div>
          <div class="col-span-3">
            <div style="height: 5pt" />
            <!-- <h1 style="margin-top: 30pt;">
                  Right Now
              </h1> -->
          </div>
          <div class="col-span-3 sm:col-span-1">
            <div class="w-weather-card">
              <span class="header">Feels Like</span>
              <span class="big"
                >{{
                  $f(weather.properties.apparentTemperature.values[0].value)
                }}
                ℉</span
              >
            </div>
          </div>
          <div class="col-span-3 sm:col-span-1">
            <div class="w-weather-card">
              <span class="header"
                >Chance of
                {{
                  forecast.properties.periods[0].shortForecast
                    .toLowerCase()
                    .includes("snow")
                    ? "Snow"
                    : "Rain"
                }}</span
              >
              <span class="big"
                >{{
                  weather.properties.probabilityOfPrecipitation.values[0].value
                }}%</span
              >
            </div>
          </div>
          <div class="col-span-3 sm:col-span-1">
            <div class="w-weather-card">
              <span class="header">Relative Humidity</span>
              <span class="big"
                >{{
                  weather.properties.relativeHumidity.values[0].value
                }}%</span
              >
            </div>
          </div>
          <div class="col-span-3">
            <h2 style="margin-top: 20pt">Wind</h2>
          </div>
          <div class="col-span-3 sm:col-span-1">
            <div class="w-weather-card">
              <span class="header">Direction</span>
              <span class="big"
                >{{ weather.properties.windDirection.values[0].value }}°
                {{
                  $ad(weather.properties.windDirection.values[0].value)
                }}</span
              >
            </div>
          </div>
          <div class="col-span-3 sm:col-span-1">
            <div class="w-weather-card">
              <span class="header">Speed</span>
              <span class="big"
                >{{ weather.properties.windSpeed.values[0].value }} km/h</span
              >
            </div>
          </div>
          <div class="col-span-3 sm:col-span-1">
            <div class="w-weather-card">
              <span class="header">Gust</span>
              <span class="big"
                >{{ weather.properties.windGust.values[0].value }} km/h</span
              >
            </div>
          </div>
          <div class="col-span-3">
            <h2 style="margin-top: 20pt">Precipitation</h2>
          </div>
          <div class="col-span-3 sm:col-span-1">
            <div class="w-weather-card">
              <span class="header">Rain Accum.</span>
              <span class="big"
                >{{
                  weather.properties.quantitativePrecipitation.values[0].value
                }}
                mm</span
              >
            </div>
          </div>
          <div class="col-span-3 sm:col-span-1">
            <div class="w-weather-card">
              <span class="header">Dew Point</span>
              <span class="big"
                >{{ $f(weather.properties.dewpoint.values[0].value) }} ℉</span
              >
            </div>
          </div>
          <div class="col-span-3 sm:col-span-1">
            <div class="w-weather-card">
              <span class="header">Snow Accum.</span>
              <span class="big"
                >{{
                  weather.properties.snowfallAmount.values[0].value
                }}
                mm</span
              >
            </div>
          </div>
          <div class="col-span-3">
            <!-- <div class="w-home-icon-spaced" align="center">
              <MjIcon name="chevron-down" />
            </div> -->
            <h2 style="margin-top: 20pt">Hourly Forecast</h2>
          </div>
          <div class="col-span-3 w-hour-container">
            <div
              align="center"
              v-for="period in forecast.properties.periods.slice(0, 12)"
              :key="period.number"
              class="w-hour"
            >
              <div class="isday">
                <MjIcon :name="period.isDaytime ? 'sun' : 'moon'" />
              </div>
              <div
                :style="{ backgroundImage: `url(${period.icon})` }"
                class="icon"
              />
              <span class="time">{{ $dt(period.startTime) }}</span>
              <span class="temp">{{ period.temperature }} ℉</span>
              <small>{{ period.shortForecast }}</small>
            </div>
          </div>
          <div class="col-span-3">
            <div class="w-home-icon-spaced" align="center">
              <MjIcon name="chevron-down" />
            </div>
            <h2 style="margin-top: 30pt">More Weather</h2>
          </div>
          <div class="col-span-3" align="center">
            <search-box />
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="w-weather-heading-img" />
      <div class="w-triangle flipped" />
      <div class="w-content" align="left">
        <div class="grid grid-cols-3 gap-4">
          <div class="w-header-card col-span-3 sm:col-span-1"></div>
          <div class="col-span-3 sm:col-span-2">
            <MjSkeleton />
          </div>
          <div class="col-span-3">
            <div style="height: 5pt" />
            <!-- <h1 style="margin-top: 30pt;">
                  Right Now
              </h1> -->
          </div>
          <div class="col-span-3 sm:col-span-1">
            <div class="w-weather-card">
              <MjSkeleton />
              <MjSkeleton />
            </div>
          </div>
          <div class="col-span-3 sm:col-span-1">
            <div class="w-weather-card">
              <MjSkeleton />
              <MjSkeleton />
            </div>
          </div>
          <div class="col-span-3 sm:col-span-1">
            <div class="w-weather-card">
              <MjSkeleton />
              <MjSkeleton />
            </div>
          </div>
          <div class="col-span-3">
            <h2 style="margin-top: 20pt"><MjSkeleton /></h2>
          </div>
          <div class="col-span-3 sm:col-span-1">
            <div class="w-weather-card">
              <MjSkeleton />
              <MjSkeleton />
            </div>
          </div>
          <div class="col-span-3 sm:col-span-1">
            <div class="w-weather-card">
              <MjSkeleton />
              <MjSkeleton />
            </div>
          </div>
          <div class="col-span-3 sm:col-span-1">
            <div class="w-weather-card">
              <MjSkeleton />
              <MjSkeleton />
            </div>
          </div>
          <div class="col-span-3">
            <h2 style="margin-top: 20pt"><MjSkeleton /></h2>
          </div>
          <div class="col-span-3 sm:col-span-1">
            <div class="w-weather-card">
              <MjSkeleton />
              <MjSkeleton />
            </div>
          </div>
          <div class="col-span-3 sm:col-span-1">
            <div class="w-weather-card">
              <MjSkeleton />
              <MjSkeleton />
            </div>
          </div>
          <div class="col-span-3 sm:col-span-1">
            <div class="w-weather-card">
              <MjSkeleton />
              <MjSkeleton />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SearchBox from "../components/SearchBox.vue";
import { Search } from "../js/search";

export default {
  components: { SearchBox },
  name: "Weather",
  data() {
    return {
      location: null,
      weather: null,
      forecast: null,
      coords: null,
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    async fetch() {
      this.weather = null;
      console.log("fetching", this.office, this.lat, this.lng);
      try {
        var results = await Search.weather(this.office, this.lat, this.lng);
        this.weather = results[0];
        this.forecast = results[1];
        this.location = results[2];
        this.coords = results[3];
      } catch (err) {
        console.log(err);
      }
      console.log(this.location, this.weather, this.coords);
    },
  },
  watch: {
    $route() {
      window.scrollTo(0,0);
      this.fetch();
    },
  },
  computed: {
    lat() {
      return this.$route.params.lat;
    },
    lng() {
      return this.$route.params.lng;
    },
    office() {
      return this.$route.params.id;
    },
  },
};
</script>

<style>
</style>